import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

const StartCompareToolTip = () => {
  const { t } = useTranslation('common');

  return (
    <Container>
      <span className="tooltip-text">{t('start_compare')} </span>
    </Container>
  );
};

const Container = styled.div`
  width: 120px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #015d9ad1;
  padding: 6px 2px;
  border-radius: 8px;
  top: 130%;

  .tooltip-text {
    color: #fff;
    font-size: ${props => props.theme.font.sizes.s};
    font-family: materialMedium;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    inset-inline-end: 50%;
    margin-inline-end: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #015c9a transparent;
  }
`;

export default StartCompareToolTip;
