import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { EntityTypes } from '../../../new-lib/constants/common';
import { useTranslation } from 'next-i18next';
import ImageWrapper from '../../ImageWrapper';
import { getImagePath } from '../../../helpers/common';

interface CardTitleSectionProps {
  entityData: {
    compoundName: string;
    propertyType: { name: string };
    area: string;
    logo_path: string;
    has_offer: boolean;
    isNawyNow?: boolean;
  };
  entityType: string;
}

const CardTitleSection = ({
  entityData,
  entityType
}: CardTitleSectionProps) => {
  const { t } = useTranslation('common');
  const isMobile = useSelector((state: any) => state.app.isMobile);

  const getEntityTitle = () => {
    if (entityType === EntityTypes.COMPOUND) {
      return entityData.compoundName;
    }
    if (entityType === EntityTypes.PROPERTY) {
      return isMobile
        ? entityData.compoundName
        : `${entityData.propertyType?.name} - ${entityData.compoundName}`;
    }
  };

  return (
    <Container isNawyNow={entityData.isNawyNow}>
      <h2 className="info-section">
        <span className="card-title" data-cy="card-title">
          {getEntityTitle()}{' '}
        </span>
        <span className="card-subtitle">{entityData.area}</span>
      </h2>

      {isMobile ? (
        <div className="developer-img">
          <ImageWrapper
            fill
            objectFit="cover"
            src={getImagePath(entityData.logo_path)}
            alt="developer logo"
            imageSize="vLow"
            sizes="50vw"
          />
        </div>
      ) : (
        entityData.has_offer && (
          <div className="cityscape-label">{t('offers')}</div>
        )
      )}
    </Container>
  );
};

const Container = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .info-section {
    display: flex;
    flex-direction: column;
    gap: 2px;
    flex: 1 100%;
    min-width: 0;
    margin-inline-end: 8px;
    span.card-title {
      font-family: materialMedium;
      font-size: ${props => props.theme.font.sizes.l};
      color: ${props =>
        props.isNawyNow
          ? `${props.theme.light.colors.white} `
          : `${props.theme.light.colors.black}`};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: start;
    }
    span.card-subtitle {
      font-family: materialRegular;
      text-align: start;
      font-size: ${props => props.theme.font.sizes.xxs};
      color: ${props =>
        props.isNawyNow ? `${props.theme.light.colors.white}` : '#646464'};
    }
  }
  .developer-img {
    width: 65px;
    height: 50px;
    background-color: #ffffff;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
  }

  .cityscape-label {
    background-color: #ff9108;
    color: #fff;
    font-family: materialSemiBold;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 8px 8px;
    font-size: ${props => props.theme.font.sizes.xxs};
    text-transform: capitalize;
    border-radius: 4px;
  }
`;

export default CardTitleSection;
