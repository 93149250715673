import styled from 'styled-components';
import { MouseEvent, ReactNode } from 'react';

interface CardBtnProps {
  icon: ReactNode;
  name: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  iconWidth?: string;
  iconHeight?: string;
}
const CardBtnPresentation = ({
  icon,
  name,
  onClick,
  iconWidth,
  iconHeight
}: CardBtnProps) => {
  return (
    <Container
      onClick={(e: any) => onClick(e)}
      iconWidth={iconWidth}
      iconHeight={iconHeight}
      className="card-btn"
      aria-label="card-action-icon"
    >
      {icon}
    </Container>
  );
};

const Container = styled.button<any>`
  position: relative;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  svg {
    width: ${props => (props.iconWidth ? props.iconWidth : '55%')};
    height: ${props => (props.iconHeight ? props.iconHeight : '55%')};
  }
`;

export default CardBtnPresentation;
