import styled from 'styled-components';
import { CardClickEventData } from '../../new-lib/types/common';
import ImageWrapper from '../ImageWrapper';
import { getImagePath } from '../../helpers/common';
import CardShareIcon from './components/CardShareBtn';
import { EntityTypes } from '../../new-lib/constants/common';
import { useSelector } from 'react-redux';
import CardFavBtn from './components/CardFavBtn';
import CardCompareBtn from './components/CardCompareBtn';
import { useTranslation } from 'next-i18next';
import ImageInfoSection from './components/ImageInfoSection';
import CardTitleSection from './components/CardTitleSection';
import CompoundPropTypes from './components/CompoundPropTypes';
import PropertyDetails from './components/PropertyDetails';
import PriceDetails from './components/PriceDetails';
import CardCTA from './components/CardCTA';
import Link from 'next/link';
import CardTags from './CardTags';
import { handleCardClickEvent } from '../../helpers/cards';
import { useRouter } from 'next/router';

let HOST = process.env.NEXT_PUBLIC_HOST;

interface CardProps {
  entity: any;
  clickEventData: CardClickEventData;
  entityType: EntityTypes;
  cardPosition: number | undefined;
  recommendedClick?: boolean;
  isNawyNow?: boolean;
}

const GridCard = ({
  entity,
  clickEventData,
  entityType,
  cardPosition,
  recommendedClick,
  isNawyNow
}: CardProps): JSX.Element => {
  const { t } = useTranslation('common');
  const favorites = useSelector(
    (state: any) => state.favorites[`${entityType}Favorites`]
  );
  const checkIsFavorite = () => {
    return favorites.some((id: any) => id === entity.id);
  };

  const isMobile = useSelector((state: any) => state.app.isMobile);

  const { locale } = useRouter();

  return (
    <Link
      href={{
        pathname:
          entityType == EntityTypes.COMPOUND
            ? `/compound/${entity.slug}`
            : `/compound/${entity.compound?.slug}/property/${
                entity?.slug || entity?.id
              }`,
        query: { ...(recommendedClick && { recommended: true }) }
      }}
      style={{ width: '100%' }}
      onClick={() => handleCardClickEvent(clickEventData)}
    >
      <Container>
        <UpperSection isNawyNow>
          <ImageWrapper
            src={getImagePath(entity.image)}
            fill
            className="card-image"
            objectFit="cover"
            alt={entity.slug}
            dataTest="card-image"
            imageSize="high"
            quality={50}
            sizes="70vw"
            withSkeleton
          />
          {!isMobile &&
            (entity.is_launch_summary ||
              (entity.tags && entity.tags.length > 0)) && (
              <CardTags
                tags={[
                  ...(entity.tags as Array<string>),
                  ...(entity.is_launch_summary ? ['Launch'] : [])
                ]}
              />
            )}
          <div className="inner-content">
            <div className="card-action-btns">
              <CardCompareBtn
                clickEventData={clickEventData}
                entityData={{ id: entity.id, min_price: entity.min_price }}
                entityType={entityType}
                showToolTip={cardPosition === 0}
              />
              <CardShareIcon
                clickEventData={clickEventData}
                entityData={{ id: entity.id, min_price: entity.min_price }}
                cardPosition={cardPosition}
                shareUrl={
                  entityType == EntityTypes.PROPERTY
                    ? `${HOST}/${locale === 'ar' ? 'ar/' : ''}compound/${
                        entity?.compound?.slug
                      }/property/${entity?.slug}`
                    : `${HOST}/${locale === 'ar' ? 'ar/' : ''}compound/${
                        entity.slug
                      }`
                }
              />
              <CardFavBtn
                clickEventData={clickEventData}
                entity={entity}
                entityType={entityType}
                isFavorite={checkIsFavorite()}
              />
            </div>
            <ImageInfoSection
              entityType={entityType}
              entityData={{
                has_launches: entity?.hasLaunch,
                has_offer: entity?.has_offer,
                property_type: entity?.property_type,
                tags: entity?.tags || [],
                logo_path: entity.developer.logo_path,
                isNawyNow: isNawyNow,
                min_ready_by: entity.min_ready_by
              }}
            />
          </div>
        </UpperSection>
        <LowerSection isNawyNow={isNawyNow}>
          <CardTitleSection
            entityData={{
              compoundName: entity?.compound?.name || entity.name,
              propertyType:
                entityType == EntityTypes.PROPERTY && entity?.property_type,
              area: `${entity?.area.name}, ${t('egypt')}`,
              logo_path: entity.developer.logo_path,
              has_offer: entity?.has_offer,
              isNawyNow
            }}
            entityType={entityType}
          />

          {entityType == EntityTypes.COMPOUND ? (
            <CompoundPropTypes
              propertyTypes={
                entity.property_types_count_highlighted ||
                entity.property_types_count
              }
            />
          ) : (
            <PropertyDetails
              entityData={{
                min_ready_by: entity.min_ready_by,
                min_unit_area: entity.min_unit_area,
                number_of_bathrooms: entity.number_of_bathrooms,
                number_of_bedrooms: entity.number_of_bedrooms,
                isNawyNow: isNawyNow
              }}
            />
          )}
          <div className="price-cta-section">
            <PriceDetails
              entityType={entityType}
              entityData={{
                max_installment_years: entity.max_installment_years,
                max_installment_years_months:
                  entity.max_installment_years_months,
                min_down_payment: entity.min_down_payment,
                min_installments: entity.min_installments,
                min_price: entity.min_price,
                currency: entity.currency,
                isNawyNow
              }}
            />

            <CardCTA
              clickEventData={clickEventData}
              entity={entity}
              entityType={entityType}
            />
          </div>
        </LowerSection>
      </Container>
    </Link>
  );
};

const Container = styled.div<any>`
  width: 100%;
  border-radius: ${props => props.theme.borderRadius};
  overflow: hidden;
  border: 1px solid #e6e6e6;
  cursor: pointer;
  .developer-img {
    width: 45px;
    height: 45px;
    background-color: #ffffff;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
  }
`;

const UpperSection = styled.div<any>`
  position: relative;
  width: 100%;
  height: ${props => (props.isNawyNow ? '216px' : '235px')};

  .inner-content {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(360deg, #000000 -25%, rgba(0, 0, 0, 0) 50%);
    .card-action-btns {
      display: flex;
      flex-direction: row;
      gap: 4px;
      justify-content: flex-end;
    }
    .img-lower-section {
      width: 100;
    }
  }
`;

const LowerSection = styled.div<any>`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props =>
    props.isNawyNow && props.theme.light.colors.secondaryBlue};
  .price-cta-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export default GridCard;
