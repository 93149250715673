import { MouseEvent } from 'react';
import { handleCardContactEvent } from '../../../helpers/cards';
import { EntityTypes } from '../../../new-lib/constants/common';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ButtonIcon from '../../ui/ButtonIcon';
import { theme } from '../../../styles/theme';
import WhatsappIcon from '../../svg-icons/WhatsappIcon';
import CallIcon from '../../svg-icons/CallIcon';
import { getPhoneNumber } from '../../../helpers/common';
import WhatsappBuilder from '../../../helpers/whatsapp/whatsapp-builder';
import { useRouter } from 'next/router';
import { phoneHelper } from '../../../helpers/phone-helper';
import { trackEvent } from '../../../new-lib/remarketing';

interface CardCTAProps {
  entity: any;
  entityType: EntityTypes;
  clickEventData: any;
}

const CardCTA = ({ entity, entityType, clickEventData }: CardCTAProps) => {
  let { asPath } = useRouter();
  const referer = useSelector((state: any) => state.app.referer);
  const userId = useSelector((state: any) => state.app.userId);
  const eventId = useSelector((state: any) => state.app.eventId);
  const PHONE_NUMBER = getPhoneNumber(referer);
  const whatsapp = new WhatsappBuilder(
    entity,
    asPath,
    referer,
    userId,
    eventId,
    entityType as any
  );

  const handleWhatsAppClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    whatsapp.launchWhatsApp();

    handleCardContactEvent({
      ...{
        ...clickEventData,
        area_id: entity?.area?.id || entity?.area_id,
        area_name: entity?.area?.name
      },
      eventAction: 'whatsapp',
      eventId: eventId,
      entityType: entityType
    });
    trackEvent.purchase({
      listingIds: [entity.id],
      listingPrice: entity.min_price
    });
  };
  const handlePhoneCall = (e: MouseEvent) => {
    e.stopPropagation();
    phoneHelper(entity, asPath, Number(PHONE_NUMBER), entityType as any);
    handleCardContactEvent({
      ...clickEventData,
      eventAction: 'call',
      eventId: eventId,
      entityType: entityType
    });
    trackEvent.purchase({
      listingIds: [entity.id],
      listingPrice: entity.min_price
    });
  };

  const router = useRouter();

  return (
    <Container>
      <ButtonIcon
        backgroundColor="#E4EBF2"
        color={theme.light.colors.primaryBlue}
        border="none"
        icon={<CallIcon />}
        onClick={e => {
          e.preventDefault();
          handlePhoneCall(e);
          router.push(`tel:+2${PHONE_NUMBER}`);
        }}
        data-test={`call-${PHONE_NUMBER}`}
      />

      <ButtonIcon
        backgroundColor="#4CD964"
        color={theme.light.colors.white}
        border="none"
        icon={<WhatsappIcon />}
        onClick={(event: any) => handleWhatsAppClick(event)}
        dataTest={`whatsapp-btn`}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;

  button {
    width: 36px;
    height: 36px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
export default CardCTA;
