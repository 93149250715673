import {
  hideCompareTooltip,
  updateCompareItems
} from '../../../redux/slices/compareSlice';
import { MouseEvent } from 'react';
import { EntityTypes } from '../../../new-lib/constants/common';
import { handleCardBtnClick } from '../../../helpers/cards';
import { useDispatch, useSelector } from 'react-redux';
import CompareIcon from '../../svg-icons/CompareIcon';
import { getCompareListIds } from '../../../features/compare/helpers';
import CardBtnPresentation from './CardBtnPresentation';
import styled from 'styled-components';
import StartCompareToolTip from '../../../features/compare/components/StartCompareToolTip';

interface CardCompareBtnProps {
  entityType: EntityTypes;
  entityData: { id: number; min_price: number };
  clickEventData: any;
  showToolTip?: boolean;
}

interface ContainerProps {
  isActive: boolean;
}

const CardCompareBtn = ({
  entityData,
  entityType,
  showToolTip,
  clickEventData
}: CardCompareBtnProps) => {
  const isMobile = useSelector((state: any) => state.app.isMobile);

  const showStartCompare = useSelector(
    (state: any) => state.compare.show_compare_tooltip
  );
  const compareListIds = useSelector(state =>
    getCompareListIds(state, entityType)
  );

  const dispatch = useDispatch();

  const entityIsInCompareList = () => {
    return compareListIds.includes(entityData?.id);
  };

  const handleClick = (e: MouseEvent) => {
    handleCardBtnClick(
      e,
      { ...clickEventData, featureName: 'compare' },
      entityIsInCompareList() ? 'cmpr_remove' : 'cmpr_add',
      entityData.id,
      entityData.min_price
    );
    dispatch(
      updateCompareItems({
        compare_type:
          entityType === EntityTypes.COMPOUND ? 'compounds' : 'properties',
        item_id: entityData.id,
        isMobile,
        fireEvent: false
      })
    );
    dispatch(hideCompareTooltip());
  };

  return (
    <Container isActive={entityIsInCompareList()}>
      <CardBtnPresentation
        name="compare"
        icon={<CompareIcon fill="#fff" />}
        iconWidth="18px"
        iconHeight="18px"
        onClick={handleClick}
      />
      {showToolTip && showStartCompare && <StartCompareToolTip />}
    </Container>
  );
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .card-btn {
    background-color: ${props =>
      props.isActive ? '#015C9A' : 'rgba(0, 0, 0, 0.7)'};
  }
`;

export default CardCompareBtn;
