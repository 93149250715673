import styled from 'styled-components';
import { tagsBackgroundColor } from '../../helpers/cards';
import { useTranslation } from 'next-i18next';

interface CardTagsProps {
  tags: string[];
  isCustom?: boolean;
  withMargin?: boolean;
}

const CardTags = ({ tags = [], isCustom, withMargin }: CardTagsProps) => {
  const { t } = useTranslation('common');

  const getTagName = (tag: string) => {
    if (tag === 'Resale' || tag === 'إعادة بيع') {
      return t('resale');
    }
    if (tag === 'Launch') {
      return t('launch_tag');
    } else {
      return tag;
    }
  };

  return (
    <TagsWraper className="tags" isCustom={isCustom} withMargin={withMargin}>
      {isCustom
        ? tags.map(tag => {
            return (
              (tag === 'Launch' ||
                tag === 'اطلاق جديد' ||
                tag === 'Resale' ||
                tag === 'Nawy Now' ||
                tag === 'إعادة بيع' ||
                tag === 'Offer' ||
                tag === 'عرض') && (
                <div
                  key={tag}
                  className="tag"
                  style={{ background: tagsBackgroundColor.get(tag) }}
                >
                  <p>{getTagName(tag)}</p>
                </div>
              )
            );
          })
        : tags.slice(0, 2).map(tag => (
            <div
              key={tag}
              className="tag"
              style={{ background: tagsBackgroundColor.get(tag) }}
            >
              <p>{getTagName(tag)}</p>
            </div>
          ))}
    </TagsWraper>
  );
};

const TagsWraper = styled.div<any>`
  z-index: 9;
  display: flex;
  inset-inline-start: 16px;
  gap: 5px;
  flex-wrap: wrap;
  top: ${props => (props.isCustom ? '0px' : '16px')};
  position: ${props => (props.isCustom ? 'unset' : 'absolute')};
  margin-inline-start: ${props =>
    props.isCustom && props.withMargin ? '8px' : '0px'};
  .tag {
    width: auto;
    padding: 3px 8px;
    background: #6d5c84;
    border-radius: ${props => props.theme.borderRadius};
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    p {
      font-size: ${props => props.theme.font.sizes.xxs};
      color: ${props => props.theme.light.colors.white};
    }
  }
`;

export default CardTags;
