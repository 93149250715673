import { logEvent } from '../new-lib/analytics';
import {
  CardClickEventData,
  CardContactEventData
} from '../new-lib/types/common';
import { trackEvent } from '../new-lib/remarketing';

interface handleDeliveryReturn {
  firstLine: string;
  secondline: string | number;
}

export const handleDelivery = (ready_by: string): handleDeliveryReturn => {
  const readyByYear = parseInt(ready_by.split('-')[0], 10);
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;

  if (readyByYear > currentYear && readyByYear <= nextYear) {
    return { firstLine: 'near', secondline: 'delivery' };
  } else if (readyByYear <= currentYear) {
    return { firstLine: 'ready_to', secondline: 'move' };
  } else {
    return { firstLine: 'delivery_in', secondline: readyByYear.toString() };
  }
};

export const handleCardBtnClick = (
  e: any,
  clickEventData: any,
  eventName: string,
  entityId: number,
  entityMinPrice: number
) => {
  e.preventDefault();
  e.stopPropagation();
  handleCardClickEvent({
    ...clickEventData,
    eventAction: eventName
  });
  trackEvent.initiateCheckout({
    listingIds: [entityId],
    listingPrice: entityMinPrice
  });
};

export const handleCardClickEvent = (clickEventData: CardClickEventData) => {
  logEvent.click(
    clickEventData.eventAction,
    clickEventData.eventLocation!,
    clickEventData.featureName,
    {
      recommended: clickEventData.recommended!,
      ...(clickEventData.recommended && {
        recommended_type: clickEventData.property?.financing
          ? 'Financing'
          : 'General'
      }),
      prop_id: clickEventData.property?.id,
      prop_name: clickEventData.property?.name,
      cmpnd_id: clickEventData.compound?.id,
      cmpnd_name: clickEventData.compound?.name,
      area_id: clickEventData?.area?.id,
      area_name: clickEventData?.area?.name,
      ...(clickEventData.index !== undefined && {
        index: clickEventData.index + 1
      }),
      page_no: clickEventData.cardPageNumber
    }
  );
};
export const handleCardContactEvent = (
  contactEventData: CardContactEventData
) => {
  const randomEventId = localStorage.getItem('random_event_id');
  logEvent.contact(
    contactEventData.eventAction,
    contactEventData?.eventLocation!,
    contactEventData.eventId ?? randomEventId,
    {
      recommended: contactEventData.recommended!,
      ...(contactEventData.recommended && {
        recommended_type: contactEventData.property?.financing
          ? 'Financing'
          : 'General'
      }),
      prop_id: contactEventData.property?.id,
      prop_name: contactEventData.property?.name,
      cmpnd_id: contactEventData.compound?.id,
      cmpnd_name: contactEventData.compound?.name,
      area_id: contactEventData?.area_id,
      area_name: contactEventData?.area_name,
      ...(contactEventData.index !== undefined && {
        index: contactEventData.index + 1
      }),
      page_no: contactEventData.cardPageNumber
    }
  );
};

export const tagsBackgroundColor = new Map();
tagsBackgroundColor.set('Resale', '#7DCBC1');
tagsBackgroundColor.set('إعادة بيع', '#7DCBC1');
tagsBackgroundColor.set('Nawy Now', '#FF3A00');
tagsBackgroundColor.set('New', '#015C9A');
tagsBackgroundColor.set('جديد', '#015C9A');
tagsBackgroundColor.set('Best Selling', '#00BDCF');
tagsBackgroundColor.set('الأكثر مبيعا', '#00BDCF');
tagsBackgroundColor.set('Limited Time Offer', '#FF9108');
tagsBackgroundColor.set('عرض لفترة محدودة', '#FF9108');
tagsBackgroundColor.set('Offer', '#FF9108');
tagsBackgroundColor.set('عرض', '#FF9108');
tagsBackgroundColor.set('Launch', '#E0C294');
tagsBackgroundColor.set('اطلاق جديد', '#E0C294');

export const tagsColor = new Map();
tagsColor.set('Launch', '#212427');
tagsColor.set('اطلاق جديد', '#212427');
