import { MouseEvent } from 'react';
import { handleCardBtnClick } from '../../../helpers/cards';
import CardBtnPresentation from './CardBtnPresentation';
import ShareIcon from '../../svg-icons/ShareIcon';
import { toast } from 'react-toastify';
import { useTranslation } from 'next-i18next';

interface CardShareBtnProps {
  entityData: { id: number; min_price: number };
  cardPosition?: number;
  clickEventData: any;
  shareUrl: string;
}

const CardShareBtn = ({
  entityData,
  clickEventData,
  shareUrl,
  cardPosition
}: CardShareBtnProps) => {
  const { t } = useTranslation('common');
  const handleClick = (e: MouseEvent) => {
    handleCardBtnClick(
      e,
      { ...clickEventData, cardPosition },
      'share_click',
      entityData.id,
      entityData.min_price
    );

    navigator.clipboard.writeText(shareUrl);
    toast.info(t('copied_to_clipboard'));
  };

  return (
    <CardBtnPresentation
      name="share"
      icon={<ShareIcon />}
      onClick={handleClick}
      iconWidth="15px"
      iconHeight="15px"
    />
  );
};

export default CardShareBtn;
