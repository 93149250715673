import styled from 'styled-components';
import { useTranslation } from 'next-i18next';

interface CompoundPropTypesProps {
  propertyTypes: { highlighted: any; rest: any };
}

const CompoundPropTypes = ({ propertyTypes }: CompoundPropTypesProps) => {
  const { t } = useTranslation('common');

  const getPropertyTypes = () => {
    const highlighted = propertyTypes?.highlighted || [];
    const rest = propertyTypes?.rest || propertyTypes;
    const more = highlighted?.length + rest?.length - 3;
    return (
      <>
        {highlighted?.slice(0, 3).map((item: any) => (
          <span className="text-1 selected" key={item.name}>
            {item.count} {item.name}
          </span>
        ))}

        {rest?.slice(0, 3 - highlighted?.length).map((item: any) => (
          <span className="text-1" key={item.name}>
            {item.count} {item.name}
          </span>
        ))}
        {more > 0 && <span className="text-1">{`${more} ${t('more')}`}</span>}
      </>
    );
  };

  return <Container>{getPropertyTypes()}</Container>;
};

const Container = styled.div<any>`
  margin: 16px 0;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow: hidden;
  span {
    font-size: ${props => props.theme.font.sizes.xs};
    color: ${props => props.theme.light.colors.midGray};
    font-family: materialMedium;
    margin-inline-end: 5px;

    &:not(:first-child) {
      border-inline-start: 1px solid #b6b6b6;
      padding-inline-start: 5px;
    }

    &.selected {
      color: ${props => props.theme.light.colors.primaryOrange};
    }
  }
`;

export default CompoundPropTypes;
