import { MouseEvent } from 'react';
import { handleCardBtnClick } from '../../../helpers/cards';
import CardBtnPresentation from './CardBtnPresentation';
import { handleClickTheFavorite } from '../../../helpers/favorites';
import { EntityTypes } from '../../../new-lib/constants/common';
import { useDispatch } from 'react-redux';
import FavoriteIcon from '../../svg-icons/FavoriteIcon';
import Cookies from 'universal-cookie';

interface CardFavBtnProps {
  entity: any;
  entityType: EntityTypes;
  clickEventData: any;
  isFavorite: boolean;
}

const CardFavBtn = ({
  entity,
  entityType,
  clickEventData,
  isFavorite
}: CardFavBtnProps) => {
  const dispatch = useDispatch();

  const handleClick = (e: MouseEvent) => {
    handleCardBtnClick(
      e,
      { ...clickEventData, featureName: 'favorite' },
      isFavorite ? 'fav_remove' : 'fav_add',
      entity.id,
      entity.min_price
    );

    const payload = {
      client_id: new Cookies().get('clientID'),
      entity_id: entity.id
    };

    handleClickTheFavorite(isFavorite, entityType, dispatch, payload);
  };

  return (
    <CardBtnPresentation
      name="favorite"
      icon={
        <FavoriteIcon
          stroke={isFavorite ? 'red' : '#fff'}
          fill={isFavorite ? 'red' : 'transparent'}
        />
      }
      iconWidth="20px"
      iconHeight="20px"
      onClick={handleClick}
    />
  );
};

export default CardFavBtn;
